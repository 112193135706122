import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ArrowIcon from "../Icons/ArrowIcon"

const CaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const CaseCard = styled.div`
  position: relative;

  & .gatsby-image-wrapper {
    position: relative;
    width: 100%;
  }
  & h2 {
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-top: 28px;
    margin-bottom: 13px;
    > a{
      color: #062c44;
      &:hover, &:focus{
        color:#ed602b;
      }
    }
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    color: #010920;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 30px;
  }
`

const CaseView = styled.div`
  position: absolute;
  bottom: -5px;
  right: 5px;
  & :hover {
    right: 0px;
    cursor: pointer;
  }
`

const CaseStudiesList = ({ data }) => (
  <CaseGrid>
    {data.map((item, i) => {
      return(
        <CaseCard>
          <Link to={`/case-studies${item.node.slug}/`}>
            <Img
              fluid={item.node.thumbnail.fluid}
              alt={item.node.title}
            />
          </Link>
          <h2><Link to={`/case-studies${item.node.slug}/`}>{item.node.title}</Link></h2>
          <p>{item.node.content.childMarkdownRemark.excerpt}</p>
          <CaseView><Link to={`/case-studies${item.node.slug}/`}><ArrowIcon fill="#ED602B" /></Link></CaseView>
        </CaseCard>
      )
    })}
  </CaseGrid>
)

export default CaseStudiesList
